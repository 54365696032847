import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import BlocImage from "../../components/dynamicZone/blocImage/blocImage";
import HeaderImage from "../../components/header/headerImage/headerImage";
import BoxContent from "../../components/boxContent/boxContent";
import TextImageContent from "../../components/textImageContent/textImageContent";

import "./style.scss";

// bloc image
import imgBlocImage from "../../images/nos-valeurs/bloc_image.webp";

// header
import imgHeader from "../../images/nos-valeurs/header.webp";

// section 1
import imgHomme from "../../images/nos-valeurs/homme.webp";
import imgEngin from "../../images/nos-valeurs/engin.webp";
import imgDrone from "../../images/nos-valeurs/drone.webp";

// section 2
import imgVehicule from "../../images/nos-valeurs/vehicule.webp";
import imgPerceuse from "../../images/nos-valeurs/perceuse.webp";
import imgHelicoptere from "../../images/nos-valeurs/helicoptere.webp";

// section 3
import img1 from "../../images/nos-valeurs/photo-s3-1.webp";
import img8 from "../../images/nos-valeurs/photo-8.webp";
import img3 from "../../images/nos-valeurs/metre.webp";

const NosValeurs = ({ location }) => {
  return (
    <>
      <Helmet>
        <body className="nos-valeurs" />
      </Helmet>
      <Layout>
        <SEO title="Nos valeurs" description="Enfin un assureur qui parle votre langage. Celui du BTP. Être accompagné par un assureur que vous connaissez et qui vous connaît, ça fait toute la différence."/>
        <HeaderImage
         imagePath={imgHeader}
         title="Les valeurs de notre groupe"
         text="Enfin un assureur qui parle votre langage. Celui du BTP. Être accompagné par un assureur que vous connaissez et qui vous connaît, ça fait toute la différence."
         location={location}
         crumbLabel="Nos valeurs"
        />

        <section className="nos-valeurs__section nos-valeurs__section--grey">
          <h2>Nos valeurs. <br/> <strong>Notre engagement.</strong></h2>
          <div className="nos-valeurs__box">
            <div className="nos-valeurs__box__items">
                  <BoxContent>
                    <h3>
                      Un service de <strong>proximité</strong> et de terrain.
                    </h3>
                    <p>
                      <strong>
                        Nos sociétaires nous connaissent et nous connaissons nos
                        sociétaires
                      </strong>
                      . A L’Auxiliaire, vous n’aurez jamais de plateforme
                      téléphonique, mais toujours un service de{" "}
                      <strong>proximité</strong>.
                    </p>
                  </BoxContent>
                  <BoxContent>
                  <h3>
                    Un partenaire assureur <strong>expert</strong> et pérenne.
                  </h3>
                  <p>
                    Fondé en 1863 par un groupe d’entrepreneurs du BTP,
                    L’Auxiliaire est proche des{" "}
                    <strong>fédérations professionnelles</strong> et propose des
                    contrats d’assurance adaptés à vos besoins.{" "}
                  </p>
                  </BoxContent>            
                  <BoxContent>
                    <h3>
                      L’Auxiliaire est une mutuelle. La <strong>confiance</strong>, ça
                      fait la différence.
                    </h3>
                    <p>
                      <strong>Assureur mutualiste</strong>, L’Auxiliaire n’a pas
                      d’actionnaires mais est gouvernée par un conseil
                      d’administration composé de{" "}
                      <strong>chefs d’entreprises du BTP</strong>. Et ça change tout.
                    </p>
                  </BoxContent>
            </div>
          </div>
        </section>
        <section className="nos-valeurs__section">
          <h2>
            Redécouvrez <br /> le sens <strong>du mot proximité&nbsp;!</strong>
          </h2>
          <div className="nos-valeurs__content">
            <TextImageContent imgAlt="Un promoteur immobilier sur un échafaudage évoque le chantier en construction sur lequel il travaille muni de ses EPIs" imgPath={imgHomme} isImgFirst={false}>
              <p>
                Les sociétaires de L’Auxiliaire bénéficient de l’accompagnement
                quotidien d’une <strong>équipe locale</strong> qui leur est
                dédiée.
                <br />
                <br />
                Vous souhaitez nous appeler&nbsp;? Vous tomberez évidemment sur{" "}
                <strong>
                  la personne que vous attendiez... et personne d’autre&nbsp;!
                </strong>
              </p>
            </TextImageContent>
            <TextImageContent imgAlt="Deux ouvriers de la construction sont autour d'engins en construction sur une chantier en extérieur" imgPath={imgEngin} isImgFirst={true}>
              <p>
                Car <strong>chaque sociétaire</strong> et chaque entreprise sont
                uniques, nos contrats sont adaptés à vos besoins et à votre
                situation spécifique.
                <br />
                <br />
                Vos interlocuteurs de L’Auxiliaire se déplacent régulièrement
                dans vos locaux ou directement{" "}
                <strong>sur vos chantiers </strong>
                afin d’évaluer vos risques et faire évoluer votre protection si
                besoin.
              </p>
            </TextImageContent>
            <TextImageContent imgAlt="Un ouvrier du bâtiment conduit un drone dans un entrepôt avec sa manette de commande" imgPath={imgDrone} isImgFirst={false}>
              <p>
                Être proche de nos sociétaires, c’est également nous adapter à
                vos nouveaux usages et à vos{" "}
                <strong>exigences d’excellence et de réactivité.</strong>
                <br />
                <br />
                Nous misons aussi sur les nouvelles technologies pour{" "}
                <strong>raccourcir les délais de traitement</strong> de vos
                dossiers. Ainsi, nous avons développé la{" "}
                <strong>visio-expertise</strong> pour les sinistres dommages
                comme pour ceux liés à la responsabilité civile.
              </p>
            </TextImageContent>
          </div>
        </section>
        <section className="nos-valeurs__section nos-valeurs__section--grey">
          <h2>
            Ne confiez plus vos risques{" "}
            <strong>au premier assureur venu.</strong>
          </h2>
          <div className="nos-valeurs__content">
            <TextImageContent imgAlt="Photographie en gros plan d'un engin de construction sur chenille dans un chantier en altitude avec de la neige" imgPath={imgVehicule} isImgFirst={false}>
              <p>
                L’Auxiliaire assure les entrepreneurs du BTP et leurs ouvrages
                <strong> depuis près de 160 ans.</strong>
                <br />
                <br />
                Les risques liés au BTP et à la construction peuvent être des{" "}
                <strong>risques longs et complexes</strong>. S’entourer de
                partenaires spécialistes est donc primordial dans la bonne gestion de
                votre activité. Notre{" "}
                <strong>pérennité et notre solidité financière</strong> nous
                classent dans cette catégorie.
              </p>
            </TextImageContent>
            <TextImageContent imgAlt="Un artisan plaquiste réalise la pose d'un raille de suspension de cloison dans un chantier de construction" imgPath={imgPerceuse} isImgFirst={true}>
              <p>
                Nos experts en bâtiment vous apportent leur appui technique en
                cas de besoin afin de valider vos méthodes et procédés.
                <br />
                <br />
                Nous mettons conjointement en place une démarche prévention afin
                de minimiser vos risques et inscrire votre entreprise dans une
                démarche de limitation de votre sinistralité.
              </p>
            </TextImageContent>
            <TextImageContent imgAlt="Un hélicoptère dédié à la construction réalise une livraison de béton en haute altitude" imgPath={imgHelicoptere} isImgFirst={false}>
              <p>
                Grâce à notre expérience solide et à notre{" "}
                <strong>connaissance fine du BTP et de sa filière</strong>, nous
                sommes en capacité d’assurer vos risques, dans vos chantiers du
                quotidien comme dans vos défis les plus ambitieux.
                <br />
                <br />
                <strong>Avec L’Auxiliaire, pas besoin de traducteur</strong>.
                Vos interlocuteurs vous comprennent et vous apportent les
                réponses les plus pertinentes à vos problématiques les plus
                techniques.
              </p>
            </TextImageContent>
          </div>
        </section>
        <BlocImage
          title="<strong>Être proches, c’est de loin le plus important.</strong>"
          text="8 sociétaires sur 10 nous recommandent (vraiment). Découvrez pourquoi&nbsp;!"
          btnText="Voir les interviews"
          btnUrl="/clients"
          className="nos-valeurs__bloc-image"
          imagePath={imgBlocImage}
        />
        <section className="nos-valeurs__section">
          <h2>
            Ça change quoi (concrètement) <strong>le mutualisme&nbsp;?</strong>
          </h2>
          <div className="nos-valeurs__content">
            <TextImageContent imgAlt="Un artisan soudeur réalise une soudure sur une poutre en métal avec ses lunettes de protection" imgPath={img1} isImgFirst={false}>
              <p>
                L’Auxiliaire est une mutuelle d’assurance et ses membres sont
                appelés des <strong>sociétaires</strong>.
                <br />
                <br />
                <strong>Ensemble</strong>, ils contribuent à renforcer la
                solidité de L’Auxiliaire, ce qui bénéficie à chacun d’entre eux.
                Chaque sociétaire est donc{" "}
                <strong>acteur de sa protection.</strong>
              </p>
            </TextImageContent>
            <TextImageContent imgAlt="Un homme et une femme spécialiste du courtage échangent dans un bureau sur leur travaille" imgPath={img8} isImgFirst={true}>
              <p>
                L’Auxiliaire est gouverné par un Conseil d’Administration
                composé <strong>exclusivement</strong> de chefs d’entreprises du
                BTP. Ce sont eux qui valident les{" "}
                <strong>orientations stratégiques</strong> de la Mutuelle et qui
                représentent l’ensemble des sociétaires lors des Assemblées
                Générales.
                <br />
                <br />
                Sans dividende à verser, seuls les sociétaires de L’Auxiliaire
                profitent des <strong>investissements</strong> réalisés par
                L’Auxiliaire&nbsp;!
              </p>
            </TextImageContent>
            <TextImageContent imgAlt="Un artisan plaquiste réalise des mesures avant la pose d'un meuble dans un chantier de construction" imgPath={img3} isImgFirst={false}>
              <p>
                Être sociétaire de L’Auxiliaire, c’est faire perdurer l’esprit
                de <strong>protection collective</strong> initié en 1863 lorsque
                plusieurs entrepreneurs lyonnais se sont groupés pour se
                prémunir mutuellement contre les accidents du travail.
              </p>
            </TextImageContent>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default NosValeurs;
