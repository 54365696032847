import React from "react";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";

import CustomBreadcrumb from "../../customBreadcrumb/customBreadcrumb";
import ScrollButton from "../../button/scrollButton/scrollButton";

import logoLinkedin from "../../../images/articles/logo-linkedin.png";

import "./headerImage.scss";

export default function HeaderImage({
  imagePath,
  title,
  text,
  secondText,
  date,
  author,
  location,
  crumbLabel,
}) {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: crumbLabel,
  });
  return (
    <header className="header-image" id="header">
      <div className="header-image__content">
        <CustomBreadcrumb crumbs={crumbs} />
        <h1>{title}</h1>
        <p>{text}</p>
        {secondText && <p>{secondText}</p>}
        {date && <time className="header-image__date">{date}</time>}
        {author && (
          <div className="header-image__author">
            par
            <span className="header-image__author-name">
              {author.Prenom} {author.Nom}
            </span>
            {author.Linkedin && (
              <a href={author.Linkedin} target="_blank" rel="noreferrer">
                <img src={logoLinkedin} alt="logo linkedin" width={18} height={18}/>
              </a>
            )}
          </div>
        )}
      </div>
      <div
        className="header-image__image"
        style={{ backgroundImage: `url(${imagePath})` }}
      ></div>
      <ScrollButton headerElemId='header' className="header-image__btn" />
    </header>
  );
}
