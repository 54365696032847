import React from "react";

import "./textImageContent.scss";

export default function TextImageContent({
  children,
  isImgFirst,
  imgPath,
  imgAlt,
}) {
  return (
    <div
      className="textImage"
      style={{ flexDirection: isImgFirst ? "row" : "row-reverse" }}
    >
      <img src={imgPath} alt={imgAlt} width={400} height={400}/>
      <div className="textImage__content">{children}</div>
    </div>
  );
}
