import React from "react";

import "./scrollButton.scss";

import scrollArrow from "../../../images/button/scroll-arrow.svg";

export default function ScrollButton({ className, headerElemId }) {
  return (
    <div
      onClick={() => scroll(0, document.getElementById(headerElemId).offsetHeight)}
      className={`${className ? className : ""} scroll-button`}
    >
      <img src={scrollArrow} alt="flèche vers le bas" />
    </div>
  );
}
